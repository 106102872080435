@use "variables" as *;

.search-app-paging-list {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  .search-app-paging-indicator-list {
    margin: 1.5rem;
    button {
      padding: 9px 18px;
      margin: 0 6px;
      &.selected {
        border-radius: 100%;
        color: white;
        background-color: $primaryLight;
      }
    }
  }
  .search-app-paging-indicator {
    padding: 0;
    background-image: $chevron;
    width: 0.625rem;
    height: 1.15rem;
    background-repeat: no-repeat;
    background-size: cover;
    &.inverse {
      transform: rotate(180deg);
    }
  }
}
