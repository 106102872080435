div.sui-layout-body {
  width: 100%;
  background: inherit;
  .sui-layout-main-body {
    max-width: 808px;
    em {
      position: relative;
      color: #3a56e4;
      font-weight: 700;
      font-style: inherit;
    }
    em:after {
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      background: rgba(37, 139, 248, 0.08);
      pointer-events: none;
    }
  }
}
