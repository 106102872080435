$primary: #012a4c;
$primaryLight: #005b91;
$buttonWidth: 11px;
$buttonHeight: 2px;
$buttonElSpacing: 8px;
$buttonTransition: 0.3s;
$serif: "Source Serif Pro", serif;
$sans-serif: "Source Sans Pro", sans-serif;
$chevron: url("../../public/chevron.svg");
$filterButton: url("../../public/filterbutton.svg");
$searchIcon: url("../../public/searchicon.svg");
