.custom-checkbox-styles {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
  input.checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    border: 2px solid #005b91;
  }
  .checkbox:checked ~ .checkmark {
    background-color: #005b91;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  /* Show the checkmark when checked */
  .checkbox:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:checked:after {
    display: block;
  }
  .checkmark:after {
    left: 0.35rem;
    top: -0.1rem;
    width: 0.525rem;
    height: 1.225rem;
    border: solid white;
    border-width: 0 0.18rem 0.18rem 0;
    transform: rotate(45deg);
  }
}
