@use "variables" as *;
$mobile-breakpoint: 800px; //should be in line with the smallest tailwind breakpoint

div.sui-layout {
  align-items: center;
  padding-top: 5rem;
  .sui-layout-sidebar {
    width: 25%;
    @media (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
  .sui-layout-main {
    width: 75%;
    @media (max-width: $mobile-breakpoint) {
      width: 100%;
      padding-top: 1.5rem;
    }
  }
}

div.sui-layout-header,
div.custom-header-layout {
  display: flex;
  padding: 0;
  border-bottom: none;
  width: 100%;
  max-width: 1300px;
  div.sui-layout-header__inner,
  div.custom-header-layout__inner {
    padding: 0 1.25rem;
    @media (max-width: $mobile-breakpoint) {
      width: 100%;
      padding: 0 1rem;
    }
    form {
      .sui-search-box {
        width: 600px;
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
        }
        input.sui-search-box__submit {
          display: none;
        }
        .sui-search-box__wrapper {
          all: unset;
          width: 100%;
          position: relative;
          display: flex;
          background: rgb(255 255 255 / 1);
          border-color: rgb(0 91 145 / 1);
          border-style: solid;
          border-width: 2px;
          padding: 0.875rem 1.25rem;
          input.sui-search-box__text-input {
            all: unset;
            width: 100%;
          }
          button.sui-search-box__submit {
            all: unset;
            justify-self: flex-end;
            cursor: pointer;
            &:focus-within {
              outline: -webkit-focus-ring-color auto 1px;
            }
          }
        }
      }
    }
  }
}

.sui-layout-sidebar-toggle {
  @media (max-width: $mobile-breakpoint) {
    all: unset;
    margin-top: 1rem;
    margin-left: 1px;
    background-image: $filterButton;
    background-repeat: no-repeat;
    background-size: contain;
    width: 7rem;
    height: 3rem;
    font-size: 0;
  }
}

div.sui-layout-sidebar {
  input.sui-multi-checkbox-facet__checkbox {
    width: 1.25rem;
    height: 1.25rem;
  }
  .mobile {
    display: none;
  }
  @media (max-width: $mobile-breakpoint) {
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
    &.sui-layout-sidebar--toggled {
      display: flex;
    }
    flex-direction: column;
    padding: 24px 30px 16px;
    .sui-layout-sidebar-toggle {
      display: flex;
      justify-self: flex-end;
      order: 2;
      align-self: center;
      margin-bottom: 0;
    }
  }
}
