@use "variables" as *;
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 16px;
  color: $primary;
  font-family: $sans-serif;
}

h1 {
  font-size: 3.125rem;
  line-height: 3.9rem;
  font-weight: 600;
  font-family: $serif;
  @media (max-width: 640px) {
    font-size: 1.875rem;
    line-height: 2.375rem;
  }
}

h2 {
  font-size: 1.375rem;
  line-height: 1.75rem;
  font-weight: 600;
  font-family: $serif;
  color: $primary;
}

h3 {
  font-size: 1.125rem;
  line-height: 1.4rem;
  font-weight: 600;
  font-family: $sans-serif;
}

h3.card-title {
  font-size: 1.125rem;
  line-height: 1.4rem;
  font-weight: 600;
  font-family: $serif;
}

@mixin icon($height, $width) {
  transition-duration: $buttonTransition;
  position: absolute;
  height: $height;
  width: $width;
  top: $buttonWidth;
  background-color: $primary;
}

@mixin icon-before($height, $width, $top) {
  transition-duration: $buttonTransition;
  position: absolute;
  width: $width;
  height: $height;
  background-color: $primary;
  content: "";
  top: $top;
  left: 0;
}

@mixin icon-after($height, $width, $top) {
  transition-duration: $buttonTransition;
  position: absolute;
  width: $width;
  height: $height;
  background-color: $primary;
  content: "";
  top: $top;
  left: 0;
}

.menuBtn {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  transition-duration: $buttonTransition;
  .icon-left {
    @include icon($buttonHeight, $buttonWidth);
    left: 0px;

    &:before {
      @include icon-before($buttonHeight, $buttonWidth, -$buttonElSpacing);
    }

    &:after {
      @include icon-after($buttonHeight, $buttonWidth, $buttonElSpacing);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .icon-right {
    @include icon($buttonHeight, $buttonWidth);
    left: $buttonWidth;

    &:before {
      @include icon-before($buttonHeight, $buttonWidth, -$buttonElSpacing);
    }

    &:after {
      @include icon-after($buttonHeight, $buttonWidth, $buttonElSpacing);
    }
  }

  &.open {
    .icon-left {
      transition-duration: $buttonTransition;
      background: transparent;

      &:before {
        transform: rotateZ(45deg) scaleX(1.2) translate(2px, 2px);
      }

      &:after {
        transform: rotateZ(-45deg) scaleX(1.2) translate(2px, -2px);
      }
    }

    .icon-right {
      transition-duration: $buttonTransition;
      background: transparent;

      &:before {
        transform: rotateZ(-45deg) scaleX(1.2) translate(-2px, 2px);
      }

      &:after {
        transform: rotateZ(45deg) scaleX(1.2) translate(-2px, -2px);
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
}

// Select sort
.sui-select__control {
  background-color: #f8f8f8;
  border: 1px solid $primary;
  border-radius: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
